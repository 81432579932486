//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'colorviewItem',
    props: ['data'],
    computed: {
        surfaceName() {
            return this.$t('surface.' + this.data.surface?.trim())
        },
        colorName() {
            let name
            if (this.$i18n.locale === 'de') {
                name = this.data?.['de'] || this.data?.name
            } else {
                name = this.data?.['en'] || this.data?.name
            }
            return name + ', ' + this.surfaceName + (this.data.extKey ? ', ' + this.data.extKey : '');
        },
        isBicolor() {
            return this.data?.parent?.key === 'Bicolour';
        },
        image() {
            if (!this.data?.colorImage?.fullpath) return null;

            return `${this.$config.pimcoreUrl}/${this.data.colorImage.fullpath}`.replace(/([^:]\/)\/+/g, '$1');
        },
        isGlossy() {
            return this.data?.surface !== 'matt';
        },
        style() {
            if (!this.isBicolor) {
                return { backgroundColor: this.data?.rgb };
            }

            return {
                backgroundImage: `linear-gradient(to right, #fff 0%, #fff 50%, ${this.data?.rgb} 50%, ${this.data?.rgb} 100%`,
            };
        },
        isWhite() {
            const { rgb } = this.data;

            if (!rgb) {
                return false;
            } else if (rgb.replace(/\s+/g, '') === 'rgb(255,255,255)') {
                return true;
            } else if (rgb === '#fff' || rgb === '#ffffff') {
                return true;
            }
            return false;
        },
        showBorder() {
            return this.isBicolor || this.isWhite;
        },
    },
};
