//
//
//
//
//
//
//
//
//
//
//
//

import ColorItem from '../Partials/ColorOverview/ColorItem';

export default {
    name: 'ColorOverview',
    props: ['data'],
    components: {
        ColorItem,
    },
    computed: {
        colors() {
            return this.data?.colors?.map((c) => ({
                ...c,
                name: this.$i18n.locale !== this.$i18n.defaultLocale ? c.nameInternational || c.nameDefault : c.name,
            }));
        },
    },
};
